'use client'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { noop } from 'lodash'
import { Text, Cell, Radio, Badge, Button, Spacer, Divider, Container } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import ContentLoader from 'components/ContentLoader'
import SeparatedList from 'components/SeparatedList'
import ScrollableArea from 'components/ScrollableArea'
import { CreditCardList } from 'components/CreditCardList'
import { ClickableCreditCardItemType } from 'components/CreditCardList/types'

import { PayInMethodCode } from 'constants/pay-in-method'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import {
  CreditCardModel,
  PayInMethodModel,
  WalletBalanceModel,
  CheckoutPayInMethodPromotionModel,
} from 'types/models'
import { getLocale } from 'state/intl/selectors'

import { getCreditCards } from 'data/api'
import { transformCreditCardDtos } from 'data/transformers/credit-card'

import {
  clickEvent,
  viewScreenEvent,
  viewPaymentOptionsEvent,
  clickPaymentOptionsEvent,
  checkoutPickPaymentMethodEvent,
} from 'libs/common/event-tracker/events'
import { formatCurrencyAmount } from 'libs/utils/formatString'

import PayInMethodSelectionBanner from './PayInMethodSelectionBanner'
import { CreditCardNote, PayInMethodList } from './PayInMethodList'
import { PayInMethodIncentive } from './types'

type WalletAvailability = {
  reason?: string
  isDisabled?: boolean
  balance: WalletBalanceModel | null
}

enum PaymentOptionsSubmitActions {
  ProceedBackToCheckout = 'proceed_back_to_checkout',
  ProceedToAddFirstCard = 'proceed_to_add_first_card',
  ProceedToAddOtherCard = 'proceed_to_add_other_card',
}

type Props = {
  checkoutId?: string
  isSingleCheckout?: boolean
  wallet?: WalletAvailability
  selectedCreditCardId?: string
  selectedPayInMethodId?: number
  wrapInScrollableArea?: boolean
  creditCards?: Array<CreditCardModel>
  trackTargetDetails?: { [key: string]: any }
  recommendedPayInMethodCode?: PayInMethodCode
  payInMethodIncentive?: PayInMethodIncentive | null
  payInMethodPromotion?: CheckoutPayInMethodPromotionModel | null
  onBack?: () => void
  onSelect?: (method: PayInMethodModel, card?: CreditCardModel) => void

  payInMethods: Array<PayInMethodModel>
  onAddNewCard: (creditCardPayInMehtod: PayInMethodModel) => void
  onSubmit: (method: PayInMethodModel, creditCardId?: string) => void
}

const PayInMethodSelection = ({
  onBack,
  onSubmit,
  onAddNewCard,
  wallet,
  checkoutId,
  creditCards,
  payInMethods,
  isSingleCheckout,
  payInMethodIncentive,
  payInMethodPromotion,
  wrapInScrollableArea,
  selectedCreditCardId,
  selectedPayInMethodId,
  recommendedPayInMethodCode,
  onSelect = noop,
  trackTargetDetails = {},
}: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('payment_method_selection')

  const locale = useSelector(getLocale)

  const [cards, setCards] = useState<Array<CreditCardModel>>()
  const [selectedMethod, setSelectedMethod] = useState<PayInMethodModel>()
  const [creditCardId, setCreditCardId] = useState(selectedCreditCardId)

  useEffect(() => {
    async function fetchCreditCards() {
      const response = await getCreditCards()

      if ('errors' in response) return

      setCards(transformCreditCardDtos(response.cards))
    }

    if (creditCards) {
      setCards(creditCards)

      return
    }

    fetchCreditCards()
  }, [creditCards])

  useEffect(() => {
    if (isSingleCheckout) {
      if (!checkoutId) return

      track(viewPaymentOptionsEvent({ checkoutId }))
    } else {
      track(viewScreenEvent({ screen: Screen.PaymentOptions }))
    }
  }, [track, isSingleCheckout, checkoutId])

  useEffect(() => {
    function getSelectedPayInMethod() {
      if (!selectedPayInMethodId || !payInMethods.length) return undefined

      return payInMethods.find(({ id }) => id === selectedPayInMethodId)
    }

    function getCreditCardPayInMethod() {
      return payInMethods.find(({ code }) => code === PayInMethodCode.CreditCard)
    }

    if (selectedMethod) return

    const method = getSelectedPayInMethod() || getCreditCardPayInMethod()

    if (!method) return

    setSelectedMethod(method)
  }, [payInMethods, selectedMethod, selectedPayInMethodId])

  useEffect(() => {
    if (!selectedCreditCardId) return

    const card = cards?.find(({ id }) => id === selectedCreditCardId)

    setCreditCardId(card?.id)
  }, [cards, selectedCreditCardId])

  function isSubmitDisabled() {
    return !!(selectedMethod?.requiresCreditCard && cards?.length && !creditCardId)
  }

  function trackClick(target: ClickableElement) {
    const event = clickEvent({
      target,
      targetDetails: JSON.stringify({
        ...trackTargetDetails,
      }),
      screen: Screen.PaymentOptions,
    })

    track(event)
  }

  function trackPickPaymentMethod(method: PayInMethodModel) {
    if (!trackTargetDetails.transactionId) return

    const event = checkoutPickPaymentMethodEvent({
      transactionId: trackTargetDetails.transactionId,
      eventTrackingCode: method.translatedName,
      screen: Screen.PaymentOptions,
    })

    track(event)
  }

  function trackSelectPaymentMethod(method: PayInMethodModel) {
    if (!isSingleCheckout) return
    if (!checkoutId || selectedMethod?.code === method.code) return

    const event = clickPaymentOptionsEvent({
      checkoutId,
      paymentMethodCode: method.code,
      target: ClickableElement.SelectPaymentMethod,
    })

    track(event)
  }

  function trackSubmitPaymentMethod(action: PaymentOptionsSubmitActions, method: PayInMethodModel) {
    if (!isSingleCheckout || !checkoutId) return

    const event = clickPaymentOptionsEvent({
      action,
      checkoutId,
      paymentMethodCode: method?.code,
      target: ClickableElement.SubmitPaymentMethod,
    })

    track(event)
  }

  function handlePaymentMethodSelected(method: PayInMethodModel, card?: CreditCardModel) {
    onSelect(method, card)
  }

  function handleCreditCardClick(card: CreditCardModel) {
    const payInMethod = payInMethods.find(({ code }) => code === PayInMethodCode.CreditCard)

    if (!payInMethod) return

    setCreditCardId(card.id)
    setSelectedMethod(payInMethod)
    trackSelectPaymentMethod(payInMethod)

    handlePaymentMethodSelected(payInMethod, card)
  }

  function handlePaymentMethodClick(payInMethod: PayInMethodModel) {
    setCreditCardId(undefined)
    setSelectedMethod(payInMethod)
    trackSelectPaymentMethod(payInMethod)

    handlePaymentMethodSelected(payInMethod)
  }

  function renderCreditCardSuffix(cardId: string) {
    const isChecked = selectedMethod?.code === PayInMethodCode.CreditCard && creditCardId === cardId

    return (
      <span data-testid={`credit-card-${cardId}`}>
        <Radio name="credit-card" value={cardId} checked={isChecked} />
      </span>
    )
  }

  function renderPayInMethodBody(payInMethod: PayInMethodModel) {
    if (payInMethod.code === PayInMethodCode.CreditCard) {
      const isCardIncentive = payInMethodIncentive?.code === PayInMethodCode.CreditCard
      const incentive = isCardIncentive ? payInMethodIncentive : null

      return <CreditCardNote incentive={incentive} note={payInMethod.note} />
    }

    if (payInMethod.code === PayInMethodCode.Wallet && wallet?.balance) {
      const { reason, balance, isDisabled } = wallet
      const { availableAmount } = balance

      return (
        <>
          <Text
            text={translate('notes.wallet', {
              balance: formatCurrencyAmount(availableAmount, locale),
            })}
          />
          {isDisabled && (
            <>
              <Spacer orientation={Spacer.Orientation.Vertical} />
              <Text theme="warning" text={reason} />
            </>
          )}
        </>
      )
    }

    if (!payInMethod.note) return null

    return <Text text={payInMethod.note} />
  }

  function renderPayInMethodSuffix(payInMethod: PayInMethodModel) {
    return (
      <span data-testid={`pay-in-${payInMethod.code}`}>
        <Radio
          name="pay-in-method"
          value={payInMethod.id}
          checked={selectedMethod?.id === payInMethod.id}
        />
      </span>
    )
  }

  function renderPayInMethodBadge(payInMethod: PayInMethodModel) {
    if (payInMethod.code === payInMethodIncentive?.code) {
      const amount = formatCurrencyAmount(payInMethodIncentive.amount, locale, {
        keepFractionDigits: false,
      })
      const incentiveDescriptionKey = payInMethodIncentive.isDelayed
        ? 'delayed_incentive_badge'
        : 'instant_incentive_badge'

      return <Badge content={translate(incentiveDescriptionKey, { amount })} theme="success" />
    }

    if (payInMethod.code === recommendedPayInMethodCode) {
      return <Badge content={translate('recommended_badge')} theme="success" />
    }

    return null
  }

  function handleAddNewCard() {
    const creditCardPayInMethod = payInMethods.find(
      ({ code }) => code === PayInMethodCode.CreditCard,
    )

    if (!creditCardPayInMethod) return

    onAddNewCard(creditCardPayInMethod)

    if (isSingleCheckout) {
      if (!selectedMethod) return

      const action =
        cards?.length === 0 // TODO: set default value to [] for cards
          ? PaymentOptionsSubmitActions.ProceedToAddFirstCard
          : PaymentOptionsSubmitActions.ProceedToAddOtherCard
      trackSubmitPaymentMethod(action, selectedMethod)
    } else {
      trackClick(ClickableElement.AddNewCardFromPaymentOptions)
    }
  }

  function handleSubmitClick() {
    if (!selectedMethod) return

    if (selectedMethod.code === PayInMethodCode.CreditCard && cards?.length === 0) {
      handleAddNewCard()

      return
    }

    if (isSingleCheckout) {
      trackSubmitPaymentMethod(PaymentOptionsSubmitActions.ProceedBackToCheckout, selectedMethod)
    } else {
      trackClick(ClickableElement.SubmitPaymentMethod)
      trackPickPaymentMethod(selectedMethod)
    }

    onSubmit(selectedMethod, creditCardId)
  }

  function renderButtons() {
    return (
      <SeparatedList separator={<Spacer />}>
        <Button
          disabled={isSubmitDisabled()}
          type={Button.Type.Submit}
          text={translate('buttons.submit')}
          styling={Button.Styling.Filled}
          onClick={handleSubmitClick}
          testId="pay-in-method-selection--submit-button"
        />
        {onBack && (
          <Button styling={Button.Styling.Flat} text={translate('buttons.back')} onClick={onBack} />
        )}
      </SeparatedList>
    )
  }

  function renderAddNewCardButton() {
    return (
      <>
        <Cell
          type={Cell.Type.Navigating}
          chevron
          title={
            <>
              <Spacer size={Spacer.Size.Large} />
              {translate('add_new_credit_card')}
              <Spacer size={Spacer.Size.Medium} />
            </>
          }
          onClick={handleAddNewCard}
          testId="pay-in-method-selection--add-new-card-button"
        />
        <Divider />
      </>
    )
  }

  function renderCreditCards() {
    if (!cards) {
      return <ContentLoader testId="credit-card-list-loader" styling={ContentLoader.Styling.Wide} />
    }

    return (
      <>
        <CreditCardList
          cards={cards}
          clickableItemType={ClickableCreditCardItemType.NotExpiredClickable}
          onItemClick={handleCreditCardClick}
          renderSuffix={renderCreditCardSuffix}
        />
        {cards.length > 0 && renderAddNewCardButton()}
      </>
    )
  }

  function getVisiblePayInMethods() {
    const hideCreditCardMethodIfHasCards = ({ code }: PayInMethodModel) => {
      return code !== PayInMethodCode.CreditCard || cards?.length === 0
    }

    const isDisabled = ({ code }: PayInMethodModel) => {
      const isWalletDisabled = wallet?.isDisabled ?? false

      return code === PayInMethodCode.Wallet && isWalletDisabled
    }

    return payInMethods.filter(hideCreditCardMethodIfHasCards).map(payInMethod => ({
      payInMethod,
      isDisabled: isDisabled(payInMethod),
    }))
  }

  function renderPayInMethods() {
    return (
      <>
        <PayInMethodList
          payInMethods={getVisiblePayInMethods()}
          renderBody={renderPayInMethodBody}
          renderBadge={renderPayInMethodBadge}
          renderSuffix={renderPayInMethodSuffix}
          onItemClick={handlePaymentMethodClick}
        />
        <Spacer size={Spacer.Size.Large} />
      </>
    )
  }

  function renderContent() {
    return (
      <>
        {payInMethodPromotion && (
          <>
            <Divider />
            <Container>
              <PayInMethodSelectionBanner payInMethodPromotion={payInMethodPromotion} />
            </Container>
            <Divider />
          </>
        )}
        {renderCreditCards()}
        <Spacer size={Spacer.Size.Large} />
        {renderPayInMethods()}
      </>
    )
  }

  function renderFullContent() {
    return (
      <Cell>
        {renderContent()}
        {renderButtons()}
      </Cell>
    )
  }

  function renderScrollableContent() {
    return (
      <>
        <ScrollableArea>{renderContent()}</ScrollableArea>
        <Cell>{renderButtons()}</Cell>
      </>
    )
  }

  if (wrapInScrollableArea) return renderScrollableContent()

  return renderFullContent()
}

export default PayInMethodSelection
